import { BigNumber } from 'bignumber.js';

export function crypto({
  locale,
  value: valueParam,
  currency,
  round,
}: {
  locale: string;
  value: BigNumber.Value | bigint;
  currency?: string | null | undefined;
  round: boolean;
}) {
  const bigNumber = typeof valueParam === 'bigint' ? new BigNumber(valueParam.toString(10)) : new BigNumber(valueParam);

  const tmpFormatted = Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 4,
  }).format(
    // @ts-expect-error this works perfectly on the browser, but still has precision loss on Node.js, unfortunately
    bigNumber.toString(),
  );
  const prefix = !round || tmpFormatted.replaceAll(',', '') === bigNumber.toString(10) ? '' : '~';

  const parts = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'code',
    maximumFractionDigits: 4,
  }).formatToParts(bigNumber.toNumber());

  if (!round) {
    const decimals = getDecimals(bigNumber);
    if (decimals.length > 2) {
      const decimalIndex = parts.findIndex((it) => it.type === 'decimal');
      parts[decimalIndex + 1].value = getDecimals(bigNumber);
    }
  }

  const suffix = currency ? `\u00A0${currency}` : '';
  const value = parts
    .map((it) => it.value)
    .join('')
    .replace(/\s*USD\s*/, '');

  return `${prefix}${value}${suffix}`;
}

function getDecimals(value: BigNumber): string {
  const str = value.toFixed();
  const dotIndex = str.indexOf('.');
  if (dotIndex < 0) {
    return '';
  }

  return str.slice(dotIndex + 1);
}
