import { useCallback, useState } from 'react';

import { Action } from '../types';

/**
 * A hook to handle submitting a server action. and track state of the action client-side
 */
export function useSubmitServerAction<ActionArgs extends any, ActionResult extends any>({
  action,
  defaultError,
}: {
  action: Action<ActionArgs, ActionResult>;
  defaultError: string;
}) {
  const [result, setResult] = useState<Awaited<ReturnType<typeof action>> | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldErrors, setFieldErrors] = useState<Record<string, string[]>>({});
  const [isSubmitting, setSubmitting] = useState(false);

  const runAction = useCallback(
    async (data: ActionArgs) => {
      try {
        setSubmitting(true);
        setErrorMessage('');
        setFieldErrors({});
        setResult(null);

        const { data: responseData, error } = await action({ data, defaultError });

        if (error) {
          setErrorMessage(error.message);
          setFieldErrors(error.fieldErrors ?? {});
          return { data: null, error };
        }

        if (!responseData) {
          setErrorMessage(defaultError);
          return { data: null, error: { message: defaultError } };
        }

        setResult({ data: responseData, error: null });
        return { data: responseData, error: null };
      } catch {
        setErrorMessage(defaultError);
        return { data: null, error: { message: defaultError } };
      } finally {
        setSubmitting(false);
      }
    },
    [action, defaultError],
  );

  return {
    runAction,
    isSubmitting,
    errorMessage,
    fieldErrors,
    result,
  };
}
