import { BigNumber } from 'bignumber.js';
import { DateTime } from 'luxon';
import { createFormatter } from 'next-intl';

import { crypto as _crypto } from './crypto';

export function getFormatter({ locale, timeZone }: { locale: string; timeZone?: string | null | undefined }) {
  const formatter = createFormatter({ locale, timeZone: timeZone ?? undefined });

  return {
    dateTime({ value }: { value: string | number | Date | DateTime }) {
      return formatter.dateTime(getDateValue(value), { dateStyle: 'short', timeStyle: 'short' });
    },
    date({ value: valueParam }: { value: string | number | Date | DateTime }) {
      const value = getDateValue(valueParam);
      const valueYear = DateTime.fromJSDate(value).toUTC().year;
      const nowYear = DateTime.utc().year;

      if (valueYear === nowYear) {
        return formatter.dateTime(getDateValue(value), { day: 'numeric', month: 'short' });
      }

      return formatter.dateTime(getDateValue(value), { dateStyle: 'short' });
    },
    time({ value }: { value: string | number | Date | DateTime }) {
      return formatter.dateTime(getDateValue(value), { timeStyle: 'short' });
    },
    relativeTime({
      value,
      now,
    }: {
      value: string | number | Date | DateTime;
      now?: string | number | Date | DateTime;
    }) {
      return formatter.relativeTime(getDateValue(value), now ? getDateValue(now) : undefined);
    },
    fiat({ value, currency }: { value: BigNumber.Value | bigint; currency?: string | null | undefined }) {
      const bigNumber = typeof value === 'bigint' ? new BigNumber(value.toString(10)) : new BigNumber(value);

      const result = formatter.number(
        // @ts-expect-error this works perfectly on the browser, but still has precision loss on Node.js, unfortunately
        bigNumber.toString(),
        {
          style: 'currency',
          currency: currency ?? 'USD',
          currencyDisplay: currency ? 'narrowSymbol' : 'code',
        },
      );

      return currency ? result : result.replace(/\s*USD\s*/, '');
    },
    crypto({ value, currency }: { value: BigNumber.Value | bigint; currency?: string | null | undefined }) {
      return _crypto({ locale, value, currency, round: true });
    },
    cryptoFull({ value, currency }: { value: BigNumber.Value | bigint; currency?: string | null | undefined }) {
      return _crypto({ locale, value, currency, round: false });
    },
  };
}

function getDateValue(value: string | number | Date | DateTime): Date {
  if (DateTime.isDateTime(value)) {
    return value.toJSDate();
  }

  if (value instanceof Date) {
    return value;
  }

  if (typeof value === 'number') {
    return new Date(value);
  }

  return DateTime.fromISO(value).toJSDate();
}
